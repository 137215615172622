export const ReportVotingRoutes = {
  path: "voting-stats",
  name: "Voting Stats",
  meta: {
    breadcrumb: {
      name: 'Voting Stats',
    },
  },
  redirect: {name: 'Generate Voting Stats Report'},
  children: [
    {
      path: "generate-voting-stats-report",
      redirect: {name: 'Generate Voting Stats Report'},
      meta: {
        breadcrumb: {
          name: 'Generate Voting Stats Report',
        },
      },
      children:[
        {
          path: "",
          name: "Generate Voting Stats Report",
          component: () => import("@/views/auth/reports/voting_stats/GenerateVotingStatsReport"),
        },
        {
          path: "voting-record-by-track",
          meta: {
            breadcrumb: {
              name: 'Voting Record by Track',
            },
          },
          children:[
            {
              path: "",
              name: "Voting Record by Track",
              component: () => import("@/views/auth/reports/voting_stats/VotingRecordByTrack"),
            },
            {
              path: "",
              name: "Results by Track",
              component: () => import("@/views/auth/reports/voting_stats/ResultsByTrack"),
            }
          ]
        },
        {
          path: "voting-record-comparison",
          meta: {
            breadcrumb: {
              name: 'Voting Record Comparison',
            },
          },
          children:[
            {
              path: "",
              name: "Voting Record Comparison",
              component: () => import("@/views/auth/reports/voting_stats/VotingRecordComparison"),
            },
            {
              path: "",
              name: "Results by Voting Comparison",
              component: () => import("@/views/auth/reports/voting_stats/ResultsByVotingComparison"),
            }
          ]
        },
        {
          path: "voting-record-by-legislator",
          meta: {
            breadcrumb: {
              name: 'Voting Record by Legislator',
            },
          },
          children:[
            {
              path: "",
              name: "Voting Record by Legislator",
              component: () => import("@/views/auth/reports/voting_stats/VotingRecordByLegislator"),
            },
            {
              path: "",
              name: "Results by Legislator",
              component: () => import("@/views/auth/reports/voting_stats/ResultsByLegislator"),
            }
          ]
        },
      ]
    },
  ]
};
