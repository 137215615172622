import {ElectionStatistics} from "@/models/common/election-statistics";
import {MemberPairings} from "@/models/legislator_info/member-pairings";
import {AnalyzeBillsFiled} from "@/models/legislator_info/analyze-bills-filed";
import {LegislationAuthored, AmendmentsAuthored} from "@/models/legislator_info/legislation-authored";
import {Candidate} from "@/models/resources/candidate";


export class Staff {
    name = '';
    title= '';
    phone='';
  constructor(name= '', title= '', phone= ''){
    this.name= name;
    this.title= title;
    this.phone=phone;
  }
}
export class Committee {
  id='';
  name= '';
  constructor(id= '', name= ''){
    this.id= id;
    this.name= name;
  }
}

export class Legislator {
  lid= '';
  pid= '';
  avatar= {
    photoUrl: '',    //photoUrl
    firstname:'', //person.name
    lastname:'',
    middleName: '',
    chamber: '',
    districtNum: 0,
    leadershipPosition: '' //leadership
  };
  contact= {
    email: '', //email
    phone: null, //capitolPhone
    address: { //officialAddress
      line1: "",
      line2: "",
      line3: null,
      city: "",
      zip: "",
      state: ""
    },
    deskNum: null,
    media:{
      twitter:'',
      instagram:'',
      facebook:'',
      youtube:''
    },
    candidate: new Candidate(),
    officialWeb: '', //web
    houseDistricts: [], // ??? what is this // districts mapped from senate district
    districtOffice: { // districtOffice
      number: '',
      phone: '',
      address: {
        line1: '',
        line2: '',
        line3: null,
        city: '',
        state: '',
        zip: ''
      },
      fax:''
    },
    counties:'',
    firstElected: '',
    termExpiration: ''
  };
  electionStats= new ElectionStatistics();
  memberPairings= new MemberPairings();
  staff= [];
  committeeMembership= []; // These links go to the respective Committee Profile Page, TBD
  legislationAuthored= new LegislationAuthored();
  amendmentsAuthored = AmendmentsAuthored;
  votingRecordSearch= {
    // Need to know the list of the options of this forms
  };
  legislativeStatistics= new AnalyzeBillsFiled();
  bio= { //person...
    address: { //person.address
      line1: "",
      line2: "",
      line3: null,
      city: "",
      zip: "",
      state: ""
    },
    occupation: '',
    dob: '',
    pob: '',
    education: {
      undergrad: '',
      graduate: ''
    },
    degrees: '',
    churchAffiliation: '', // religion
    membershipActivities: '', //memberships
    spouse: '',
    childrenNum: 0,
    treasurer: {
      name: '',
      postal: '',
      region: ''
    }
  };
  districtDemographics= []; //External links
  countyDemographics= []  //External links
}
