
let API_BASE = "/api/data";
if(process.env.VUE_APP_CLOUD_API_PREFIX)
  API_BASE = process.env.VUE_APP_CLOUD_API_PREFIX+API_BASE

const LOOKUP_BASE = "/api/lookup/";
const CLIPS_BASE = "/api/clips/";
const BILL_BASE = "/api/legislation/";
const TRACK_BASE = "/api/tracking/";
const NOTES_BASE = "/api/notesdocuments/";
const VOTES_BASE = "/api/votes/";
const ALERTS_BASE = "/api/alerts/";
const BATCH_BASE = "/api/batch/";


const SESSION = {
  GET: `${API_BASE}/sessions`, // SESSION_GET
  GET_TYPES: `${API_BASE}/types`
};

const LEGISLATORS = {
  GET_ALL: (sid) => `${API_BASE}/session/${sid}/legislators`,
  GET: (mid) => `${API_BASE}/legislator/${mid}`,
  GET_PRESS_RELEASES: (mid) => `${API_BASE}/member/${mid}/pressreleases`,
};
const RESOURCES = {
  CANDIDATE :{
    GET_ALL: (sid) => `${API_BASE}/session/${sid}/candidates`,
    GET_BY_ID: (cid) => `${API_BASE}/candidate/${cid}`,
  },
};
// not used yet
const ELECTIONS = {
  GET: `${API_BASE}/election`,
};

const COMMITTEES = {
  GET_ALL: (sid) => `${API_BASE}/session/${sid}/find_committees`,
  GET: (cid) => `${API_BASE}/committee/${cid}`
};

const CLIPS = {
  GET_ALL: (sid) => `${API_BASE}/session/${sid}/clips`,
};

const TRACKING = {
  GET_ALL: ({ userId, sessionId }) =>
    TRACK_BASE + `tracks/${userId}/${sessionId}`,
  GET: ({ trackId }) => TRACK_BASE + `track/${trackId}`,
  NEW: ({ userId, sessionId }) => TRACK_BASE + `tracks/${userId}/${sessionId}`,
  UPDATE: ({ trackId }) => TRACK_BASE + `track/${trackId}`,
  UPDATE_BILLS: ({ billType, billNumber }) =>
    TRACK_BASE + `bill/${billType}/${billNumber}`,
  UPDATE_MEMOS: ({ memoType, memoNumber }) =>
    TRACK_BASE + `memo/${memoType}/${memoNumber}`,
  UPDATE_COMMITTEES: ({ committeeId }) =>
    TRACK_BASE + `committee/${committeeId}`,
  UPDATE_AGENCY: ({ agencyId }) => TRACK_BASE + `agency/${agencyId}`,
  UNTRACK_BILL: ({ trackId, type, number }) =>
    TRACK_BASE + `track/${trackId}/bill/${type}/${number}`,
  UNTRACK_MEMO: ({ trackId, type, number }) =>
    TRACK_BASE + `track/${trackId}/memo/${type}/${number}`,
  UNTRACK_COMMITTEE: ({ trackId, committeeId }) =>
    TRACK_BASE + `track/${trackId}/committee/${committeeId}`,
  UNTRACK_AGENCY: ({ trackId, agencyId }) =>
    TRACK_BASE + `track/${trackId}/agency/${agencyId}`,
  DELETE: ({ trackId }) => TRACK_BASE + `track/${trackId}`,
  UPSERT_BILL_POSITION: ({ trackId, type, number }) =>
    TRACK_BASE + `track/${trackId}/bill/${type}/${number}`,
  UPSERT_MEMO_POSITION: ({ trackId, type, number }) =>
    TRACK_BASE + `track/${trackId}/memo/${type}/${number}`,
  ADD_BILLS: ({ trackId }) => TRACK_BASE + `track/${trackId}/bills`,
  ADD_MEMOS: ({ trackId }) => TRACK_BASE + `track/${trackId}/memos`,
  ADD_COMMITTEES: ({ trackId }) => TRACK_BASE + `track/${trackId}/committees`,
  ADD_AGENCIES: ({ trackId }) => TRACK_BASE + `track/${trackId}/agencies`,
  UI: {
    AGENCIES: TRACK_BASE + "agencies",
  },
  WEB_POPPER: {
    GET: ({ trackId }) => TRACK_BASE + `web-popper/${trackId}`,
  },
};

const NOTES = {
  // Notes
  GET_NOTES: ({ billId, userId }) =>
    NOTES_BASE + `bill/${billId}/notes?userId=${userId}`,
  ADD_NOTE_TO_BILL: ({ billId }) => NOTES_BASE + `bill/${billId}/note`,
  DELETE_NOTE: ({ userId, noteId }) => NOTES_BASE + `notes/${userId}/${noteId}`,
  EDIT_NOTE: ({ userId, noteId }) => NOTES_BASE + `notes/${userId}/${noteId}`,

  // Documents
  GET_DOCS: ({ billId, userId }) =>
    NOTES_BASE + `bill/${billId}/docs?userId=${userId}`,
  ADD_DOC_TO_BILL: ({ billId }) => NOTES_BASE + `bill/${billId}/doc`,
  DELETE_DOC: ({ encodedPath }) => NOTES_BASE + `docs/${encodedPath}`,
  DOWNLOAD_DOC: ({ encodedPath }) =>
    NOTES_BASE + `docs/download/${encodedPath}`,
  DOWNLOAD_DOC_FROM: ({ encodedPath, container }) =>
    NOTES_BASE + `docs/download/${encodedPath}?container=${container}`,
};

const ATLAS = {
  TEMPLATES: {
    GET_URL: ({ userId }) => NOTES_BASE + `atlas/template/${userId}`,
    UPDATE: ({ userId }) => NOTES_BASE + `atlas/template/${userId}`,
  },
  USERS: {
    GET_ALL: ({ userId }) => NOTES_BASE + `atlas/users?userId=${userId}`,
    GET: ({ atlasId }) => NOTES_BASE + `atlas/users/${atlasId}`,
    GET_APPROVERS: ({ userId }) => NOTES_BASE + `atlas/approvers/${userId}`,
    NEW: NOTES_BASE + "atlas/users",
    UPDATE: ({ userId, atlasId }) =>
      NOTES_BASE + `atlas/user/${userId}/${atlasId}`,
  },
  ASSIGNMENTS: {
    ADD: ({ billId, analystId }) =>
      NOTES_BASE + `atlas/assignments/${billId}/${analystId}`,
    GET_ALL: ({ userId, billId }) =>
      NOTES_BASE + `atlas/assignments/bill/${billId}?userId=${userId}`,
    PENDING: ({ userId }) =>
      NOTES_BASE + `atlas/assignments/pending?userId=${userId}`,
  },
  UI: {
    GET_BILL_VERSIONS: ({ sessionId, billType, billNumber }) =>
      NOTES_BASE + `atlas/bill-versions/${sessionId}/${billType}/${billNumber}`,
  },
  ADD_NOTE: ({ billId }) => NOTES_BASE + `atlas/analysis/${billId}/note`,
  GET_ANALYST_NOTES: ({ billId, analystId }) =>
    NOTES_BASE + `atlas/analysis/${billId}/${analystId}/note`,
  ADD_DOCS: ({ billId, analystId }) =>
    NOTES_BASE + `atlas/analysis/${analystId}/${billId}/doc`,
};

const VOTES = {
  GET: ({ id }) => VOTES_BASE + `vote/${id}`,
  GET_FOR_BILL: ({ billId }) => VOTES_BASE + `bill/${billId}/votes`,
  BREAKDOWN: ({ id }) => VOTES_BASE + `vote/${id}/breakdown`,
  SUMMARY: ({ id }) => VOTES_BASE + `vote/${id}/summary`,
  COUNTS: ({ id }) => VOTES_BASE + `vote/${id}/counts`,
  PROJECTED: {
    GET: ({ id }) => VOTES_BASE + `projected-vote/${id}`,
    NEW: VOTES_BASE + "projected/new",
    UPDATE: ({ id }) => VOTES_BASE + `projected/${id}`,
    BREAKDOWN: ({ id }) => VOTES_BASE + `projected-vote/${id}/breakdown`,
    GET_FOR_BILL: ({ billId, userId }) =>
      VOTES_BASE + `bill/${billId}/projected-votes?userId=${userId}`,
  },
  UI: {
    COMMITTEES: ({ sessionId }) => VOTES_BASE + `committees/${sessionId}`,
    VOTE_TYPES: VOTES_BASE + "vote-types",
    VOTE_CATEGORIES: VOTES_BASE + "vote-categories",
    MEMBERS: ({ sessionId }) => VOTES_BASE + `members/${sessionId}`,
    BILLS: ({ sessionId }) => VOTES_BASE + `bills/${sessionId}`,
    AMENDMENTS: ({ sessionId }) => VOTES_BASE + `amendments/${sessionId}`,
  },
};

const BATCH = {
  NIGHTWRITER: {
    GET_ALL: ({ userId, sessionId }) =>
      BATCH_BASE + `nightwriter/all/${userId}/${sessionId}`,
    GET: ({ id }) => BATCH_BASE + `nightwriter/${id}`,
    GENERATE: ({ id }) => BATCH_BASE + `nightwriter/${id}/now`,
    UPSERT: BATCH_BASE + "nightwriter",
  },
  NEWSWRITER: {
    GET_ALL: ({ userId, sessionId }) =>
      BATCH_BASE + `newswriter/all/${userId}/${sessionId}`,
    GET: ({ id }) => BATCH_BASE + `newswriter/${id}`,
    GENERATE: ({ id }) => BATCH_BASE + `newswriter/${id}/now`,
    UPSERT: BATCH_BASE + "newswriter",
  },
  UI: {
    CODE_TYPES: BATCH_BASE + "code-types",
  },
};

const MEDIA = {
  PRESS_CONFERENCES: {
    GET_ALL: (sid) => `${API_BASE}/session/${sid}/pressconferences`,
  },
  PRESS_RELEASES: {
    GET_ALL: (sid) => `${API_BASE}/session/${sid}/pressreleases`,
  },
  AROUND_CAPITOL: {
    GET_ALL: (sid) => `${API_BASE}/session/${sid}/capitolstories`,
    GET_BY_ID: (csid) => `${API_BASE}/capitolstory/${csid}`,
  }
};

const LEGISLATION = {
  BILLS: {
    GET_ALL: (sid) => `${API_BASE}/session/${sid}/bills`,
    GET_BY_ID: (bid) => `${API_BASE}/bill/${bid}`,
  },
};

export {
  RESOURCES,
  COMMITTEES,
  SESSION,
  ELECTIONS,
  CLIPS,
  TRACKING,
  NOTES,
  ATLAS,
  VOTES,
  LEGISLATORS,
  BATCH,
  MEDIA,
  LEGISLATION,
};
