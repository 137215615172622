export const LegislativeSearchRoutes = {
  path: "legislative-search",
  meta: {
    breadcrumb: {
      name: 'Legislation',
    },
  },
  redirect:  { name: 'Legislative search' },
  children: [
    {
      path: "",
      name: "Legislative search",
      component: () => import("@/views/auth/search/LegislativeSearch"),
    },
    {
      path: "legislative-search-results",
      name: "Legislative Search Results",
      meta: {
        breadcrumb: {
          name: "Legislative Search Results",
        },
      },
      component: () => import("@/views/auth/search/legislative_search/LegislativeSearchResults"),
    },
    {
      path: ":billId/bill-summary",
      name: "Bill Summary",
      meta: {
        breadcrumb: {
          name: 'Bill Summary',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/BillSummary"),
      props: true,
    },
    {
      path: ":billId/bill-text",
      name: "Bill Text",
      meta: {
        breadcrumb: {
          name: 'Bill Text',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/BillText"),
      props: true,
    },
    {
      path: ":billId/remarks-documents",
      name: "Remarks & Documents",
      meta: {
        breadcrumb: {
          name: 'Remarks & Documents',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/RemarksDocuments"),
      props: true,
    },
    {
      path: ":billId/vote-locator",
      name: 'Vote Locator',
      meta: {
        breadcrumb: {
          name: 'Vote Locator',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/bill_actions/BillVoteLocator"),
      props: true,
    },
    {
      path: ":billId/amendment-table",
      name: 'Amendment Table',
      meta: {
        breadcrumb: {
          name: 'Amendment Table',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/AmendmentTable"),
      props: true,
    },
    {
      path: ":billId/statute-citation",
      name: 'Statute Citation',
      meta: {
        breadcrumb: {
          name: 'Statute Citation',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/StatuteCitation"),
      props: true,
    },
    {
      path: ":billId/history-notes",
      name: 'History & Notes',
      meta: {
        breadcrumb: {
          name: 'History & Notes',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/HistoryAndNotes"),
      props: true,
    },
    {
      path: ":billId/vote-record",
      name: 'Vote Record',
      meta: {
        breadcrumb: {
          name: 'Vote Record',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/bill_actions/BillVotingRecord"),
      props: true,
    },
    {
      path: "projected-vote-generator",
      name: 'Projected Vote',
      meta: {
        breadcrumb: {
          name: 'Projected Vote Generator',
        },
      },
      component: () => import("../views/auth/search/legislative_search/bill_actions/ProjectedVoteGenerator"),
    },
    {
      path: ":billId/committee-news",
      name: 'Committee News',
      meta: {
        breadcrumb: {
          name: 'Committee News',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/BillCommitteeNews"),
      props: true,
    },
    {
      path: ':billId/news',
      name: 'News',
      meta: {
        breadcrumb: {
          name: 'News',
        },
      },
      component: () => import("@/views/auth/search/legislative_search/BillNews"),
      props: true,
    },
  ],
};
