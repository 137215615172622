export const cal_logo = [`
<svg width="64" height="64" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_615_40499)">
  <path d="M14.5001 3.5H3.5V14.5001H14.5001V3.5Z" fill="white"/>
  <path d="M9.83984 7.45987L10.3438 8.17987L11.1358 7.60387V11.7799H11.9999V6.30786H11.2799L9.83984 7.45987Z" fill="#1E88E5"/>
  <path d="M8.47152 8.87258C8.78402 8.58558 8.97802 8.18757 8.97802 7.74807C8.97802 6.87457 8.21151 6.16406 7.26951 6.16406C6.4685 6.16406 5.7835 6.66857 5.6045 7.39057L6.433 7.60107C6.5155 7.26907 6.86701 7.02807 7.26951 7.02807C7.74051 7.02807 8.12401 7.35107 8.12401 7.74807C8.12401 8.14507 7.74051 8.46807 7.26951 8.46807H6.77101V9.33208H7.26951C7.81001 9.33208 8.26601 9.70758 8.26601 10.1521C8.26601 10.6041 7.83301 10.9721 7.30051 10.9721C6.81951 10.9721 6.4085 10.6671 6.3435 10.2631L5.5 10.4011C5.631 11.2191 6.405 11.8361 7.30001 11.8361C8.30351 11.8361 9.12002 11.0806 9.12002 10.1521C9.12002 9.64058 8.86802 9.18158 8.47152 8.87258Z" fill="#1E88E5"/>
  <path d="M14.0001 18H4L3.5 16L4 14H14.0001L14.5001 16L14.0001 18Z" fill="#FBC02D"/>
  <path d="M16 14.5001L18 14.0001V4L16 3.5L14 4V14.0001L16 14.5001Z" fill="#4CAF50"/>
  <path d="M14.0001 4.00002L14.5001 2.00001L14.0001 0H1.50001C0.671503 0 0 0.671503 0 1.50001V14.0001L2.00001 14.5001L4.00002 14.0001V4.00002H14.0001Z" fill="#1E88E5"/>
  <path d="M14 14V18L18 14H14Z" fill="#E53935"/>
  <path d="M16.5 0H14V4.00002H18V1.50001C18 0.671503 17.3285 0 16.5 0Z" fill="#1565C0"/>
  <path d="M1.50001 18H4.00002V14H0V16.5C0 17.3285 0.671503 18 1.50001 18Z" fill="#1565C0"/>
</g>
<defs>
  <clipPath id="clip0_615_40499">
    <rect width="18" height="18" fill="white"/>
  </clipPath>
  </defs>
</svg>`]
