class Subject{
    name= '';
    totalBills= 0;
    fileRate= 0;
    filedBills=0;
    passingRate=0;
    rejectedBills=0;
    rejectedRate=0;
  constructor(name= '', totalBills= 0, fileRate= 0,
              filedBills= 0, passingRate= 0, rejectedBills=0, rejectedRate=0){
    this.name= name;
    this.totalBills= totalBills;
    this.fileRate= fileRate;
    this.filedBills=filedBills;
    this.passingRate=passingRate;
    this.rejectedBills=rejectedBills;
    this.rejectedRate=rejectedRate;
  }
}

export class AnalyzeBillsFiled {
  subjects= [new Subject()]
}
