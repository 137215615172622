import { Module } from "vuex";
import {CreateTrackRequest, Track} from "@/models/tracking/track";
import TrackingService from "@/services/tracking.service";


interface RequestState<T> {
  loading: boolean
  data?: T
}

interface TrackingState {
  tracks: RequestState<Track[]>
  error?: any
}

export const trackingModule: Module<TrackingState, any> = {
  state: {
    tracks: {
      loading: true,
    }
  },
  getters: {
    isLoadingTracks: (state: TrackingState) => state.tracks.loading,
    tracks: state => state.tracks.data,
  },
  actions: {
    getTracks({commit}, [sessionId, userId]: [string, string]) {
      commit('beginGetTracksRequest');
      TrackingService.getTracks(sessionId, userId)
        .then((res) => {
          commit('setTracks', res)
        })
        .catch((err) => {
          commit('setTracksError', err)
        });
    },
    createTrack({commit, dispatch}, req: CreateTrackRequest) {
      TrackingService.createTrack(req)
        .then(() => {
          // Refresh get tracks
          dispatch('getTracks');
        })
        .catch((err) => {
          commit('setError', err);
        });
    },
  },
  mutations: {
    beginGetTracksRequest(state) {
      state.tracks.loading = true;
    },
    setTracks(state, tracks: Track[]) {
      state.tracks.data = tracks;
      state.error = null;
      state.tracks.loading = false;
    },
    setError(state, error: any) {
      state.error = error;
      state.tracks.loading = false;
    },
  }
};


