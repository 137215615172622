
export class CommitteeAvatar {
  photoUrl= '';
  firstname='';
  lastname='';
  leadershipPosition= '';
  districtNum= 0;
  phone= '';
  address= '';
  constructor(photoUrl= '', firstname='', lastname='',
              leadershipPosition= '', districtNum= 0,
  phone= '', address= ''
) {
    this.photoUrl = photoUrl;
    this.firstname = firstname;
    this.lastname = lastname;
    this.leadershipPosition = leadershipPosition;
    this.districtNum = districtNum;
    this.phone = phone;
    this.address = address;
  }
}

export class Committee {
  id = '';
  name='';
  majorityMembers= [new CommitteeAvatar()];
  minorityMembers= [new CommitteeAvatar()];
  previousMembers=[new CommitteeAvatar()];
  staff = [];

}
