export const LegislatorInfoRoutes = {
  path: "legislator-info",
  name: "Legislator Info",
  meta: {
    breadcrumb: {
      name: "Legislator Info",
    },
  },
  redirect:  { name: 'Find Legislator' },
  children: [
    {
      path: "find-legislator",
      name: "Find Legislator",
      meta: {
        breadcrumb: {
          name: "Find Legislator",
        },
      },
      component: () => import("@/views/auth/session/legislator_info/FindLegislatorInfo")
    },
    {
      path: "member-profile/:legislatorId",
      meta: {
        breadcrumb: {
          name: "Member Profile",
        },
      },
      children: [
        {
          path: '',
          name: 'Member Profile',
          component: () => import("@/views/auth/session/legislator_info/MemberProfile"),
          props: true,
        },
        {
          path: "press-releases",
          name: "Press Releases of Member",
          meta: {
            breadcrumb: {
              name: "Press Releases of Member",
            },
          },
          component: () => import("@/views/auth/session/legislator_info/PressReleases"),
          props: true,
        },
        {
          path: "voting-record",
          name: "Voting Record Statistics",
          meta: {
            breadcrumb: {
              name: 'Voting Record Statistics',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/VoteRecordStatistics"),
          props: true,
        },
        {
          path: "co-sponsored-memos",
          name: "Co-Sponsor Memos",
          meta: {
            breadcrumb: {
              name: 'Co-Sponsor Memos',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationRelatedTemplate"),
          props: true,
        },
        {
          path: "legislation-authored",
          name: "Legislation Authored",
          meta: {
            breadcrumb: {
              name: 'Legislation Authored',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationAuthored"),
          props: true,
        },
        {
          path: "legislation-enacted",
          name: "Legislation Enacted",
          meta: {
            breadcrumb: {
              name: 'Legislation Enacted',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationRelatedTemplate"),
          props: true,
        },
        {
          path: "legislation-co-sponsored",
          name: "Legislation Co-Sponsored",
          meta: {
            breadcrumb: {
              name: 'Legislation Co-Sponsored',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationRelatedTemplate"),
          props: true,
        },
        {
          path: "amendments-authored",
          name: "Amendments Authored",
          meta: {
            breadcrumb: {
              name: 'Amendments Authored',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationRelatedTemplate"),
          props: true,
        },
        {
          path: "bill-research-report/:subject",
          name: "Bill Research Report",
          meta: {
            breadcrumb: {
              name: 'Bill Research Report',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/LegislationRelatedTemplate"),
          props: true,
        },
        {
          path: "analyze-bills-filed",
          name: "Analyze Bills Filed",
          meta: {
            breadcrumb: {
              name: 'Analyze Bills Filed',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/AnalyzeBillsFiled"),
          props: true,
        },
        {
          path: "member-pairings-analysis",
          name: "Member Pairings Analysis",
          meta: {
            breadcrumb: {
              name: 'Member Pairings Analysis',
            },
          },
          component: () => import("@/views/auth/session/legislator_info/MemberPairingsAnalysis"),
          props: true,
        },
      ],
    },
  ]
}


