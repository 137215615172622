import axios from "axios";
import {CreateTrackRequest, Track} from "@/models/tracking/track";
import {TRACKING} from "@/configuration/api-routes";

class TrackingService {
  getTracks(userId: string, sessionId: string) {
    const uri = TRACKING.GET_ALL({
      userId: userId,
      sessionId: sessionId,
    });

    return axios.get(uri).then((res) => {
        return res.data;
      }).catch((err) => {
        return []; // TODO: if errors out, data is empty, need to be refined later
      });
  }

  createTrack(req: CreateTrackRequest) {
    const uri = TRACKING.NEW({
      userId: req.userId,
      sessionId: req.sessionId,
    });

    return axios.post(uri, req);
  }
}

export default new TrackingService();
