import { Module } from "vuex";
import {Committee} from "@/models/committee";
import CommitteeService from "@/services/committee-service"

export const committeeModule:Module<any, any> = {
  namespaced: true,
  state: {
    committee: new Committee()
  },
  actions: {
    loadBillData({ commit }, payload){
      // return CommitteeService.getBillDataByLegislator(payload[0], payload[1]).then(
      //   bills => {
      //     commit('loadMemberPairingData', bills[0]);
      //     commit('loadLegislationAuthored', bills[1]);
      //     return Promise.resolve(bills);
      //   },
      //   error => {
      //     commit('loadFailure');
      //     return Promise.reject(error);
      //   }
      // )
    },
    unload({ commit }) {
      commit('unloaded');
    }
  },
  mutations: {
    load(state, committee) {
      state.committee = committee;
    },
    loadElectionData(state, electionStat) {
      // state.legislatorInfo.electionStats = Object.assign({},
      //   state.legislatorInfo.electionStats, electionStat);
    },
    unloaded(state) {
      state.legislatorInfo = null;
    },
  },
  getters:{
    getCommittee(state){
      return state.committee;
    },
    getElectionStats(state){
//      return state.legislatorInfo.electionStats;
    }
  }
}
