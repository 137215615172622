import "core-js/stable";
import { createApp } from 'vue'
import CoreUIVue from "@coreui/vue-pro";
// @ts-ignore
import App from "./App";
import router from "./router/index";
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import "@fontsource/manrope";

// from https://www.bezkoder.com/jwt-vue-vuex-authentication/
//import VeeValidate from "vee-validate";

import {Amplify} from "@aws-amplify/core";
Amplify.configure(
  {
    Auth: {
      userPoolId: process.env.VUE_APP_CLOUD_AMPLIFY_AUTH_USERPOOLID,
      region: process.env.VUE_APP_CLOUD_AMPLIFY_AUTH_REGION, // Amazon Cognito Region
      userPoolWebClientId: process.env.VUE_APP_CLOUD_AMPLIFY_AUTH_USERPOOLWEBCLIENTID,
    }
  }
);
const app = createApp(App)
const ComponentContext = require.context('./', true, /\.vue$/i, 'lazy');

ComponentContext.keys().forEach((componentFilePath) => {
  // @ts-ignore
  const componentName = componentFilePath.split('/').pop().split('.')[0];
  app.component(componentName, () => ComponentContext(componentFilePath));
});

app.use(store)
app.use(router)
//app.use(VeeValidate);
app.use(CoreUIVue);
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mount('#app')
