import axios from "axios";
import {CandidateAvatar, Candidate} from "@/models/resources/candidate";
import {RESOURCES} from "@/configuration/api-routes";

class ResourcesService {

  loadCandidates(sid: any){
    return axios.get(RESOURCES.CANDIDATE.GET_ALL(sid)).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return [];
    });
  }

  loadCandidateById(cid: string|number) {
    return axios.get(RESOURCES.CANDIDATE.GET_BY_ID(cid))
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);
        return {};
      });
  }

  mapCandidate(data: any){
    const candidate = new Candidate();
    candidate.id = data.id;
    candidate.avatar =new CandidateAvatar(
      '',data.person.first_name,data.person.last_name,
      '', '',0);
    candidate.contact.email = data.person.email;
    candidate.contact.phone = data.person.phone;
    candidate.contact.address1 = data.person.business_address1;
    candidate.contact.address2 = data.person.business_address2;
    candidate.contact.address3 = data.person.business_address3;
    candidate.contact.city = data.person.business_city;
    candidate.contact.state = data.person.business_state;
    candidate.contact.zip = data.person.business_zip_code;
    candidate.electionIds = data.elections.map((election: any)=> election.id);
    return candidate;
  }
  mapCandidateById(data: any, id: number){
    return data.filter((c:any)=>c.id==id).map((c:any)=>this.mapCandidate(c))[0];
  }

}

export default new ResourcesService();
