import {createStore, GetterTree, MutationTree, StoreOptions} from 'vuex'
import { auth } from './storex/auth.module'
import {legislatorInfoModule} from "./storex/legislator-info.module";
import {trackingModule} from "./storex/tracking.module";
import {committeeModule} from "@/storex/committee.module";
import {candidateModule} from "@/storex/resources/candidate.module";

interface StoreState {
  sidebarShow: boolean | 'responsive'
  sidebarMinimize: boolean
  darkMode: boolean
  asideShow: boolean
}

const state = {
  sidebarShow: true,
  sidebarMinimize: false,
  darkMode: true,
  asideShow: false
}

const mutations: MutationTree<StoreState> = {
  toggleSidebar (state) {
    state.sidebarShow = !state.sidebarShow;
  },
  set(state, [variable, value]: [keyof StoreState, any]) {
    state[variable] = value
  },
  toggle (state, variable: keyof StoreState) {
    state[variable] = !state[variable]
  },
  updateSidebarVisible(state, payload) {
    state.sidebarShow = payload.value
  },
}

const getters: GetterTree<StoreState, any> = {
  getSideBarShow(state){
    return state.sidebarShow;
  },
  getSidebarMinimize(state){
    return state.sidebarMinimize;
  }
}

export default createStore({
  state: state,
  mutations: mutations,
  getters: getters,
  modules: {
    auth,
    candidateModule,
    legislatorInfoModule,
    trackingModule,
    committeeModule
  }
});
