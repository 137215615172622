export class ElectionStatistic{
    firstname= 'First';
    lastname= 'Last';
    middleName= '';
    party='Republican';
    votes=0;
    status='';
    constructor(firstname= 'First', lastname= 'Last', middleName= '',
              party= 'Republican', votes= 0, status= ''){
    this.firstname= firstname;
    this.lastname= lastname;
    this.middleName= middleName;
    this.party=party;
    this.votes=votes;
    this.status=status;
  }
}

export class Election {
  id='';
  position= 'House of Representative';
  candidate= new ElectionStatistic();
  opponents= [new ElectionStatistic()];
}

export class ElectionStatistics {
  generalElections: Election[]= [];
  primaryElections: Election[]= [new Election()];
}


