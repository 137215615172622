import axios from "axios";

import {LEGISLATORS} from "@/configuration/api-routes.js";
import {Legislator, Staff, Committee} from "../models/legislator_info/legislator";
import {ElectionStatistics, ElectionStatistic, Election} from "../models/common/election-statistics";
import {MemberPairings, Pairing} from "../models/legislator_info/member-pairings";
import {LegislationAuthored, Bill} from "../models/legislator_info/legislation-authored";
import ElectionService from "./common/election.service";


class LegislatorInfoService {

  constructor() {
    this.legislator = new Legislator();
    this.memberParing = new MemberPairings();
    this.legislationAuthored = new LegislationAuthored();
  }
  loadLegislators(sid){
    return axios.get(LEGISLATORS.GET_ALL(sid)).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return [];
    });
  }

  getLegislatorById(lid){
    return axios.get(LEGISLATORS.GET(lid)).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return [];
    });
  }

  getBillDataByLegislator(pid, sid){
    return axios.get(LEGISLATION.BILLS.GET_ALL).then((res) => {
      let bills = res.data.filter(bill=> bill.session.id === sid);
      let uBills = {};
      bills.forEach(bill=>{
        if(bill.id in uBills){
          if(bill.version > uBills[bill.id].version)
            uBills[bill.id] = bill;
        } else{
          uBills[bill.id] = bill;
        }
      });
      //Member Pairing
      this.setMemberPairingAndLegislationParticipated(Object.values(uBills), pid);
      return [this.memberParing, this.legislationAuthored];
    }).catch((err) => {
      console.log(err);
      return {};
    });
  }

  getAmendmentsDataByLegislator(pid, sid){
    return axios.get(LEGISLATION.AMENDMENTS.GET_ALL).then((res) => {
      let amendments = res.data.filter(amendment=>
        amendment.session.id === sid && amendment.author?.person.id === pid);
      return amendments.map(amendment => this.createBillSummary(amendment.bill, pid));
    }).catch((err) => {
      console.log(err);
      return {};
    });
  }

  getLegislatorPressReleases(lid) {
    return axios.get(LEGISLATORS.GET_PRESS_RELEASES(lid)).then((res) => {
      return res.data;
    }).catch(err => {
      console.log(err);
      return [];
    })
  }

  mapElectionData(candidates){
    // const electionStats: ElectionStatistics = new ElectionStatistics();
    // candidates.elections.forEach((e:any) => {
    //   const election: Election = new Election();
    //   election.position = e.position_name;
    //   if(e.election_type_description==='General'){
    //       election.candidate = new ElectionStatistic(c.person.first_name, c.person.last_nam,'',
    //           c.party_description,c.vote,c.candidate_status_description);
    //       e.candidates.forEach((c:any)=>{
    //         election.opponents.push(
    //           new ElectionStatistic(c.person.first_name, c.person.last_name,'',
    //             c.party_description,c.vote,c.candidate_status_description))
    //       });
    //     }
    //     electionStats.generalElections.push(election);
    //   });
    // return electionStats;
  }

  setMemberPairingAndLegislationParticipated(bills, pid){
    this.legislationAuthored = new LegislationAuthored();
    this.memberParing = new MemberPairings();
    let pairings = {};
    bills.forEach(bill => {
      let b = this.createBillSummary(bill, pid);
      if(bill.cosponsors && bill.cosponsors.find(cosponsor => cosponsor.person.id === pid)){
        bill.cosponsors.forEach(cosponsor => {
          if(cosponsor.person.id !== pid){
            if(cosponsor.person.id in pairings){
              pairings[cosponsor.person.id].pair += 1;
            } else {
              pairings[cosponsor.person.id] = new Pairing(bill._id, cosponsor.person.id,
                cosponsor.person.name.last + ',' + cosponsor.person.name.first,
                cosponsor.party.name[0].toLowerCase(), 1,2,3);
            }
          } else {
            this.legislationAuthored.listLegislationCoSponsored.push(b);
          }
        });
      }
      if(bill.sponsors && bill.sponsors.find(sponsors => sponsors.person.id === pid)){
        bill.sponsors.forEach(sponsors => {
          if(sponsors.person.id !== pid){
            if(sponsors.person.id in pairings){
              pairings[sponsors.person.id].pair += 1;
            } else {
              pairings[sponsors.person.id] = new Pairing(bill._id, sponsors.person.id,
                sponsors.person.name.last + ',' + sponsors.person.name.first,
                sponsors.party.name[0].toLowerCase(), 1,2,3);
            }
          } else {
            this.legislationAuthored.listLegislationAuthored.push(b);
          }
        });
      }
    });
    this.memberParing.pairings = Object.values(pairings);
  }


  createBillSummary(bill, pid){
    if(bill) {
      let billActionDate = !bill.actions || bill.actions.length < 1 ? null :
        bill.actions[0]?.action.updated ? bill.actions[0]?.action.updated :
          bill.actions[0]?.action.copied ? bill.actions[0]?.action.copied : bill.actions[0]?.action.created;
      billActionDate = new Date(billActionDate);
      billActionDate = billActionDate.getMonth()+'-'+billActionDate.getDay()+'-'+
        billActionDate.getFullYear().toString().substring(2);
      return new Bill(bill._id, bill.type + '-' + bill.number, pid, bill.summary,
        bill.actions?.at(0)?.action?.name, billActionDate);
    }
    return new Bill();
  }
}

export default new LegislatorInfoService();
