import axios from 'axios'
import { Session, ActiveSessions } from '@/models/common/session'
import { Auth } from '@aws-amplify/auth';
import { SESSION } from "@/configuration/api-routes";
import User from "@/models/common/user";

class AuthService {
    login(user: User) {
        return Auth.signIn(user.email, user.password).then(res => {
          if (res) localStorage.setItem('user', res.username)
          return res.username;
        }).catch((error) => {
          console.log(error);
          return null;
        });
    }
    logout() {
        localStorage.removeItem('user')
    }
    loadSessions() {
      return axios.get(SESSION.GET).then((res) => {
        const now = new Date();
        const sessions: Session[] = res.data;
        const curSession = sessions.filter(x => new Date(x.start_date) < now && new Date(x.end_date) > now)[0];
        const activeSession: ActiveSessions = new ActiveSessions();
        activeSession.sessions = sessions;
        activeSession.selectedSession = curSession;
        return activeSession;
      }).catch((error) => {
          console.log(error);
          return new ActiveSessions();
        });
    }

    loadTypes(){
      return axios.get(SESSION.GET_TYPES).then((res) => {
          return res.data;
        }).catch((error) => {
          console.log(error);
          return null;
        });
    }
}

export default new AuthService()
