export class Session {
  id = 0;
  label = "";
  session_type_id = "";
  session_type_description = "";
  start_date = "";
  end_date ="";
}

export class ActiveSessions {
  sessions: Session[] = [];
  selectedSession: Session = new Session();
}
