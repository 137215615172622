import { createRouter, createWebHashHistory } from 'vue-router'
import { LegislatorInfoRoutes } from './session/legislator-info'
import { LegislativeSearchRoutes } from './legislative-search'
import { auth } from '../storex/auth.module'
import {CommitteeSearchRoutes} from "./committee/committee-search";
import {CommitteeReportRoutes} from "./committee/committee-report";
import {CandidateSearchRoutes} from "./resources/candidate-search";
import {AgencyHearingsRoutes} from "./admin/agency-hearings";
import {ReportVotingRoutes} from "./report-voting-stats";
// Containers
const TheContainer = () => import("@/containers/TheContainer");

const  routes = [
    {
      path: "/",
      redirect: "/login",
      name: "Auth",
      children: [
        {
          path: "login",
          name: "Login",
          component: () => import("@/views/public/Login"),
        }
      ],
    },
    {
      path: "/dashboard",
      component: TheContainer,
      children: [
        // Default
        {
          path: "",
          name: "Dashboard",
          meta: {
            breadcrumb: {
              name: 'Home',
            },
          },
          component: () => import("@/views/auth/Dashboard"),
        },
      ],
    },
    // Search pages
    {
      path: "/search",
      meta: {
        breadcrumb: {
          name: "Search",
        },
      },
      component: TheContainer,
      name: "Search",
      redirect:  { name: 'New Today' },
      children: [
        {
          path: "new-today",
          name: "New Today",
          meta: {
            breadcrumb: {
              name: 'Search All',
            },
          },
          component: () => import("@/views/auth/search/NewToday"),
        },
        LegislativeSearchRoutes,
      ],
    },
    // Track pages
    {
      path: "/tracks",
      name: "Tracks",
      meta: {
        breadcrumb: {
          name: 'Tracks',
        },
      },
      component: TheContainer,
      redirect: { name: 'Tracking Index' },
      children: [
        {
          path: "tracking-alerts",
          name: "Tracking Alerts",
          meta: {
            breadcrumb: {
              name: 'Tracking Alerts',
            },
          },
          component: () => import("@/views/auth/tracks/Alerts"),
        },
        {
          path: "manage-tracks",
          name: "Manage Tracks",
          meta: {
            breadcrumb: {
              name: 'Manage Tracks',
            },
          },
          component: () => import("../views/auth/tracks/manage_tracks/ManageTracks"),
        },
        {
          path: "tracking-index",
          name: "Tracking Index",
          meta: {
            breadcrumb: {
              name: 'Tracking Index',
            },
          },
          children: [
            {
              path: "",
              component: () => import("@/views/auth/tracks/tracking_index/TrackingIndex"),
            },
            {
              path: "tracked-legislation",
              name: "Tracked Legislation",
              meta: {
                breadcrumb: {
                  name: 'Tracked Legislation',
                },
              },
              component: () => import("@/views/auth/tracks/tracking_index/TrackedLegislation")
            }
          ]
        },
        {
          path: "advanced-tracking",
          name: "Advanced Tracking",
          meta: {
            breadcrumb: {
              name: 'Advanced Tracking',
            },
          },
          component: () => import("@/views/auth/tracks/AdvancedTracking"),
        },
        {
          path: "companion-bills",
          name: "Companion Bills",
          meta: {
            breadcrumb: {
              name: 'Companion Bills',
            },
          },
          children: [
            {
              path: "",
              component: () => import("../views/auth/tracks/companion_bills/CompanionBills"),
            },
            {
              path: "results",
              name: "Companion Bills Results",
              component: () => import("@/views/auth/tracks/companion_bills/CompanionBillsResult"),
            },
          ],
        },
        {
          path: "matrix",
          meta: {
            breadcrumb: {
              name: 'Matrix',
            },
          },
          children: [
            {
              path: "",
              name: "Matrix",
              component: () => import("../views/auth/tracks/matrix/Matrix"),
            },
            {
              path: "matrix-report",
              name: "Matrix Report",
              meta: {
                breadcrumb: {
                  name: 'Matrix Report',
                },
              },
              component: () => import("@/views/auth/tracks/matrix/MatrixReport"),
            },
          ],
        },
        {
          path: "tracks/new",
          name: "New Track",
          component: () => import("@/views/auth/tracks/New"),
        },
        {
          path: "track/:id",
          name: "Modify Track",
          component: () => import("@/views/auth/tracks/Update"),
        },
        {
          path: "/:catchAll(.*)",
          name: "Place Holder",
          component: () => import("@/views/auth/tracks/Tracking")
        },
      ],
    },
    // Reports
    {
      path: "/reports",
      component: TheContainer,
      children: [
        {
          path: "bill-status-report",
          name: "Bill Status Report",
          meta: {
            breadcrumb: {
              name: 'Bill Status Report',
            },
          },
          component: () => import("@/views/auth/reports/BillStatusReport"),
        },
        ReportVotingRoutes,
        {
          path: "nightwriter",
          name: "NightWriter List",
          component: () => import("@/views/auth/reports/nightwriter/List"),
        },
        {
          path: "nightwriter/setup/:id?",
          name: "NightWriter Detail",
          component: () => import("@/views/auth/reports/nightwriter/Upsert"),
        },
        {
          path: "newswriter",
          name: "NewsWriter List",
          meta: {
            breadcrumb: {
              name: 'Newswriter',
            },
          },
          component: () => import("@/views/auth/reports/newswriter/List"),
        },
        {
          path: "newswriter/setup/:id?",
          name: "NewsWriter Detail",
          meta: {
            breadcrumb: {
              name: 'Newswriter Detail',
            },
          },
          component: () => import("@/views/auth/reports/newswriter/Upsert"),
        },
        {
          path: "/:catchAll(.*)",
          name: "Place Holder",
          component: () => import("@/views/auth/reports/Reports")
        }
      ],
    },
    // Session pages
    {
      path: "/session",
      name: "Session",
      meta: {
        breadcrumb: {
          name: "Session",
        },
      },
      component: TheContainer,
      redirect:  { name: 'Find Legislator' },
      children: [
        {
          path: "bill/compare/:ids",
          name: "Bill Compare",
          component: () => import("@/views/auth/session/BillCompare"),
        },
        LegislatorInfoRoutes,
        {
          path: "compile-statistics",
          name: "Compile Statistics",
          meta: {
            breadcrumb: {
              name: "Compile Statistics",
            },
          },
          component: () => import("@/views/auth/session/session_statistics/CompileStatistics")
        },
        {
          path: "session-statistics",
          meta: {
            breadcrumb: {
              name: "Session Statistics",
            },
          },
          children: [
            {
              path: "",
              name: "Session Statistics",
              component: () => import("@/views/auth/session/session_statistics/SessionStatistics")
            },
            {
              path: "subject",
              name: "Session Statistics Subject",
              meta: {
                breadcrumb: {
                  name: "Subject",
                },
              },
              component: () => import("@/views/auth/session/session_statistics/SessionStatisticsSubject")
            }
          ]
        },
        {
          path: "/:catchAll(.*)",
          name: "Place Holder",
          component: () => import("@/views/auth/session/Session")
        }
      ]
    },
    {
      path: "/media",
      meta: {
        breadcrumb: {
          name: 'Media',
        },
      },
      component: TheContainer,
      name: 'Media',
      redirect: {path:'/media/around-capitol/'},
      children: [
        {
          path: "around-capitol",
          name: "Around The Capitol",
          meta: {
            breadcrumb: {
              name: 'Around The Capitol',
            },
          },
          children: [
            {
              path: "",
              component: () => import("@/views/auth/media/AroundCapitol")
            },
            {
              path: ":article",
              name: "Capitol Story",
              component: () => import("@/views/auth/media/AroundCapitolArticle"),
              props: true
            },
          ]
        },
        {
          path: "press-conferences",
          name: "Press Conferences",
          meta: {
            breadcrumb: {
              name: "Press Conferences",
            },
          },
          component: () => import("@/views/auth/media/PressConferences"),
        },
        {
          path: "press-releases",
          name: "Press Releases",
          meta: {
            breadcrumb: {
              name: "Press Releases",
            },
          },
          component: () => import("@/views/auth/media/PressReleases")
        },
        {
          path: "access-publications",
          name: "Access Publications",
          meta: {
            breadcrumb: {
              name: "Access Publications",
            },
          },
          component: () => import("@/views/auth/media/AccessPublications")
        }
      ],
    },
    // Committees
    {
      path: "/committees",
      name: "Committees",
      meta: {
        breadcrumb: {
          name: 'Committees',
        },
      },
      component: TheContainer,
      redirect:  { name: 'Committee Search' },
      children: [
        {
          path: "committee-search",
          meta: {
            breadcrumb: {
              name: 'Committee Search',
            },
          },
          redirect: {name: 'Committee Search'},
          children: CommitteeSearchRoutes,
        },
        {
          path: "senate-appropriations",
          meta: {
            breadcrumb: {
              name: 'Senate Appropriations',
            },
          },
          redirect: {name: 'Committee Search'},
          children: [
            {
              path: "bills-in-committee",
              name: "Bills in Committee",
              meta: {
                breadcrumb: {
                  name: 'Bills in Committee',
                },
              },
              component: () => import("@/views/auth/committees/committee_search/committee_profile/senate_appropriations/BillsInCommittee"),
            },
            {
              path: "bills-reported-from-committee",
              name: "Bills Reported From Committee",
              meta: {
                breadcrumb: {
                  name: 'Bills Reported From Committee',
                },
              },
              component: () => import("@/views/auth/committees/committee_search/committee_profile/senate_appropriations/BillsReportedFromCommittee"),
            },
            {
              path: "bills-referred-to-committee",
              name: "Bills Referred To Committee",
              meta: {
                breadcrumb: {
                  name: 'Bills Referred To Committee',
                },
              },
              component: () => import("@/views/auth/committees/committee_search/committee_profile/senate_appropriations/BillsReferredToCommittee"),
            },
          ],
        },
        {
          path: "committee-schedule",
          name: "Committee Schedule",
          meta: {
            breadcrumb: {
              name: 'Committee Schedule',
            },
          },
          component: () => import("@/views/auth/committees/CommitteeSchedule"),
        },
        CommitteeReportRoutes
      ],
    },

    //Admin - placeholder design not available
    {
      path: "/admin",
      meta: {
        breadcrumb: {
          name: 'Admin Info',
        },
      },
      component: TheContainer,
      children: [
        {
          path: "executive-orders",
          name: "Executive Orders",
          meta: {
            breadcrumb: {
              name: 'Executive Orders',
            }
          },
          component: () => import("@/views/auth/admin/ExecutiveOrders"),
        },
        {
          path: "executive-orders/results",
          name: "Executive Orders Results",
          component: () => import("@/views/auth/admin/ExecutiveOrdersResults"),
        },
        {
          path: "executive-nominations",
          meta: {
            breadcrumb: {
              name: 'Executive Nominations',
            }
          },
          redirect: {name:'Executive Nominations'},
          children: [
            {
              path: "",
              name: "Executive Nominations",
              component: () => import("../views/auth/admin/executive_nominations/ExecutiveNominations"),
            },
            {
              path: "executive-nominations-senate-floor",
              name: "Executive Nominations Senate Floor",
              meta: {
                breadcrumb: {
                  name: 'Executive Nominations From the Senate Floor',
                }
              },
              component: () => import("../views/auth/admin/executive_nominations/ExecutiveNominationsSenateFloor"),
            },
            {
              path: "executive-nominations-overview",
              name: "Executive Nominations Overview",
              meta: {
                breadcrumb: {
                  name: 'Overview',
                }
              },
              component: () => import("../views/auth/admin/executive_nominations/ExecutiveNominationsOverview"),
            }
          ]
        },
        AgencyHearingsRoutes,
        {
          path: "acts-vetoes",
          meta: {
            breadcrumb: {
              name: 'Acts & Vetoes',
            }
          },
          redirect: { name:'Acts & Vetoes' },
          children: [
            {
              path: "",
              name: "Acts & Vetoes",
              component: () => import("@/views/auth/admin/acts_vetoes/ActsVetoes")
            },
            {
              path: "general-acts-enacted",
              name: "General Acts Enacted",
              component: () => import("@/views/auth/admin/acts_vetoes/GeneralActsEnacted")
            },
            {
              path: "bills-vetoed",
              name: "Bills Vetoed",
              component: () => import("@/views/auth/admin/acts_vetoes/BillsVetoed")
            }
          ]
        },
      ],
    },
    //Resources - placeholder design not available
    {
      path: "/resources",
      name: "Resources",
      meta: {
        breadcrumb: {
          name: 'Resources',
        },
      },
      component: TheContainer,
      children: [
        CandidateSearchRoutes,
        {
          path: "/:catchAll(.*)",
          name: "",
          component: () => import("@/views/auth/resources/Resources"),
        }
      ],
    },
    //Area Maps - placeholder design not available
    {
      path: "/map",
      meta: {
        breadcrumb: {
          name: 'Atlas',
        },
      },
      component: TheContainer,
      children: [
        {
          path: "/:catchAll(.*)",
          name: "",
          component: () => import("@/views/auth/area_maps/AreaMaps"),
        }
      ],
    },
    //Clips - placeholder design not available
    {
      path: "/clips",
      meta: {
        breadcrumb: {
          name: 'Clips & Coverage HQ',
        },
      },
      redirect: {name: 'Home'},
      component: TheContainer,
      name: "Clips & Coverage HQ",
      children: [
        {
          path: "home",
          name: "Home",
          meta: {
            breadcrumb: {
              name: 'Home',
            },
          },
          component: () => import("../views/auth/clips/Home"),
        },
        {
          path: "bills-in-the-news",
          name: "Bills in The News",
          meta: {
            breadcrumb: {
              name: 'Bills in The News',
            },
          },
          component: () => import("../views/auth/clips/BillsInNews"),
          props: true,
        },
        {
          path: "legislator-in-the-news",
          name: "Legislator In The News",
          meta: {
            breadcrumb: {
              name: 'Legislators in The News',
            },
          },
          component: () => import("../views/auth/clips/LegislatorsInTheNews"),
          props: true,
        },
        {
          path: 'subject-in-the-news/:subjectId',
          name: 'Subject in The News',
          meta: {
            breadcrumb: {
              name: 'Subject in the News',
            },
          },
          component: () => import('../views/auth/clips/SubjectInTheNews'),
          props: true,
        },
        {
          path: "pa-headlines",
          name: "PA Headlines",
          meta: {
            breadcrumb: {
              name: 'PA Headlines',
            },
          },
          component: () => import("../views/auth/clips/PAHeadlines"),
        },
        {
          path: "manage-tracks",
          redirect: {name: "Manage Tracks"},
        },
        {
          path: "publish-newsletter",
          name: "Publish Newsletter",
          meta: {
            breadcrumb: {
              name: 'Publish Newsletter',
            },
          },
          component: () => import("../views/auth/clips/PublishNewsletter"),
        },
        {
          path: "newswriter",
          name: "Newswriter",
          meta: {
            breadcrumb: {
              name: 'Newswriter',
            },
          },
          component: () => import("../views/auth/clips/Newswriter"),
        },
      ],
    },
    // ATLAS
    {
      path: "/atlas",
      component: TheContainer,
      children: [
        /**** Atlas / Users ****/
        {
          path: "users",
          name: "Atlas Users",
          component: () => import("@/views/auth/atlas/Users"),
        },
        {
          path: "users/edit/:id",
          name: "Edit User",
          component: () => import("@/views/auth/atlas/UpsertUser"),
        },
        {
          path: "users/new",
          name: "New User",
          component: () => import("@/views/auth/atlas/UpsertUser"),
        },
        {
          path: "pending-analyses",
          name: "Pending ATLAS Analyses",
          component: () => import("@/views/auth/atlas/PendingAnalyses"),
        },
        {
          path: "/:catchAll(.*)",
          name: "",
          component: () => import("@/views/auth/atlas/Atlas"),
        }
      ],
    },
    //Help
    {
      path: "/help",
      component: TheContainer,
      children: [
        {
          path: "/:catchAll(.*)",
          name: "",
          component: () => import("@/views/auth/help/Help"),
        }
      ],
    },
    // Votes pages
    {
      path: "/votes",
      component: TheContainer,
      children: [
        {
          path: "num/:id",
          name: "Vote Detail by Vote",
          component: () => import("@/views/auth/votes/Numeric"),
        },
        {
          path: "alpha/:id",
          name: "Vote Detail by Name",
          component: () => import("@/views/auth/votes/Alphabetical"),
        },
        {
          path: "compare/:ids",
          name: "Vote Comparison",
          component: () => import("@/views/auth/votes/Comparison"),
        },
      ],
    },
    // Projected votes
    {
      path: "/projected-votes",
      component: TheContainer,
      children: [
        {
          path: "new",
          name: "New Projected Vote",
          component: () => import("@/views/auth/votes/UpsertProjected"),
        },
        {
          path: ":id",
          name: "Modify Projected Vote",
          component: () => import("@/views/auth/votes/UpsertProjected"),
        },
      ],
    },
  ]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && (!auth.state.userId || !auth.state.activeSessions.sessions))
    next({ name: 'Login' })
  else next()
})

export default router
