export const CommitteeSearchRoutes = [
  {
    path: '',
    name: "Committee Search",
    component: () => import("@/views/auth/committees/committee_search/CommitteeSearch"),
  },
  {
    path: "committee-profile/:committeeId",
    redirect: {name: 'Committee Profile'},
    meta: {
      breadcrumb: {
        name: 'Committee Profile',
      },
    },
    children: [
      {
        path: '',
        name: "Committee Profile",
        component: () => import("@/views/auth/committees/committee_search/committee_profile/CommitteeProfile"),
        props: true,
      },
      {
        path: "committee-news-and-commentary",
        name: "Committee News and Commentary",
        meta: {
          breadcrumb: {
            name: 'Committee News and Commentary',
          },
        },
        component: () => import("../../views/auth/committees/committee_search/committee_profile/CommitteeMeetings"),
      },
      {
        path: "committee-meetings-schedule",
        name: "Committee Meetings Schedule",
        meta: {
          breadcrumb: {
            name: 'Committee Meetings Schedule',
          },
        },
        component: () => import("../../views/auth/committees/committee_search/committee_profile/CommitteeMeetingsSchedule"),
      }
    ],
  },
  {
    path: "committee-coverage",
    name: "Committee Coverage",
    meta: {
      breadcrumb: {
        name: 'Committee Coverage',
      },
    },
    children: [
      {
        path: "senate-appropriations",
        name: "Senate Appropriations",
        meta: {
          breadcrumb: {
            name: 'Senate Appropriations',
          },
        },
        redirect: {name: 'Committee News and Commentary (Bill Meeting)'},
        children:[
          {
            path: "committee-news-and-commentary-bill-meeting",
            name: "Committee News and Commentary (Bill Meeting)",
            meta: {
              breadcrumb: {
                name: 'Committee News and Commentary',
              },
            },
            component: () => import("../../views/auth/committees/committee_search/committee_coverage/CommitteeCoverageBillMeeting"),
          },
          {
            path: "committee-news-and-commentary-public-hearing",
            name: "Committee News and Commentary (Public Hearing)",
            meta: {
              breadcrumb: {
                name: 'Committee News and Commentary',
              },
            },
            component: () => import("../../views/auth/committees/committee_search/committee_coverage/CommitteeCoveragePublicHearing"),
          }
        ]
      },
    ]
  },
  ]
