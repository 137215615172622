export const CommitteeReportRoutes = {
    path: "committee-report",
    meta: {
      breadcrumb: {
        name: 'Committee Report',
      },
    },
    children: [
      {
        path: "",
        name: "Committee Report",
        component: () => import("../../views/auth/committees/committee_report/CommitteesReport"),
      },
      {
        path: "standing-committee-meeting-schedule",
        name: "Standing Committee Meeting Schedule",
        meta: {
          breadcrumb: {
            name: 'Standing Committee Meeting Schedule',
          },
        },
        component: () => import("../../views/auth/committees/committee_report/StandingCommitteeMeetingSchedule")
      }
    ]
}
