export const AgencyHearingsRoutes = {
  path: "agency-hearings",
  meta: {
    breadcrumb: {
      name: 'Agency Hearings',
    }
  },
  redirect: {name:'Agency Hearings'},
  children: [
    {
      path: "",
      name: "Agency Hearings",
      component: () => import("@/views/auth/admin/agency_hearings/AgencyHearings"),
    },
    {
      path: "search-agency-hearings",
      meta: {
        breadcrumb: {
          name: 'Search Agency Hearings',
        }
      },
      name: "Search Agency Hearings",
      component: () => import("@/views/auth/admin/agency_hearings/SearchAgencyHearings"),
    },
    {
      path: "agency-meetings-tracking-report",
      meta: {
        breadcrumb: {
          name: 'Agency Meetings Tracking Report',
        }
      },
      name: "Agency Meetings Tracking Report",
      component: () => import("@/views/auth/admin/agency_hearings/AgencyMeetingsTrackingReport"),
    },
    {
      path: "search-agency-meetings",
      meta: {
        breadcrumb: {
          name: 'Search Agency Meetings',
        }
      },
      name: "Search Agency Meetings",
      component: () => import("@/views/auth/admin/agency_hearings/SearchAgencyMeetings"),
    },
    {
      path: "agency-hearing-result",
      meta: {
        breadcrumb: {
          name: 'Agency Hearing Result',
        }
      },
      name: "Agency Hearing Result",
      component: () => import("../../views/auth/admin/agency_hearings/AgencyHearingsResult"),
    },
    {
      path: "manage-tracked-agencies",
      meta: {
        breadcrumb: {
          name: 'Manage Tracked Agencies',
        }
      },
      name: "Manage Tracked Agencies",
      component: () => import("../../views/auth/admin/agency_hearings/ManageTrackedAgencies"),
    }
  ]
};
