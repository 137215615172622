import { Module } from "vuex";
import {Candidate} from "@/models/resources/candidate";
import ResourcesService from "@/services/resources.service"
import ElectionService from "@/services/common/election.service";

export const candidateModule: Module<any, any> = {
  namespaced: true,
  state: {
    selectedCandidate: new Candidate(),
    candidates: []
  },
  actions: {
    setCandidates({commit}, candidates){
      commit('setCandidates', candidates);
    },
    selectCandidate({ commit }, {candidates, cid}){
      console.log(candidates, cid);
      commit('setCandidate', ResourcesService.mapCandidateById(candidates, cid));
    },
    loadElectionsData({ commit }, payload){
      return ElectionService.getElections(payload[0],payload[1]).then(
        elections => {
          commit('loadElectionData', elections);
          return Promise.resolve(elections);
        },
        error => {
          commit('loadFailure');
          return Promise.reject(error);
        }
      )
    },
    unload({ commit }) {
      commit('unloaded');
    }
  },
  mutations: {
    setCandidate(state, candidate) {
      state.selectedCandidate = candidate;
    },
    setCandidates(state, candidates) {
      state.candidates = candidates;
    },
    loadElectionData(state, electionStat) {
      state.candidates.electionStats = Object.assign({},
        state.candidates.electionStats, electionStat);
    },
    unloaded(state) {
      state.legislatorInfo = null;
    },
  },
  getters:{
    getCandidate(state){
      return state.selectedCandidate;
    },
    getCandidates(state){
      return state.candidates;
    },
    getElectionStats(state){
     return state.candidates.electionStats;
    }
  }
}
