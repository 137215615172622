import axios from "axios";
import {Election, ElectionStatistic, ElectionStatistics} from "@/models/common/election-statistics";
import {ELECTIONS} from "@/configuration/api-routes";

class ElectionService {

  getElections(eid: number[], cid: number){
    return axios.get(ELECTIONS.GET, { params: { electionId: eid }}).then((res) => {
      return this.mapElections(res.data, cid);
    }).catch((err) => {
      console.log(err);
      return [];
    });
  }

  mapElections(data: any, cid: number){
    const electionStats: ElectionStatistics = new ElectionStatistics();
    data.forEach((e:any) => {
      const election: Election = new Election();
      election.position = e.position_name;
      if(e.election_type_description==='General'){
        e.candidates.forEach((c:any)=>{
          if(c.id===cid){
            election.candidate = new ElectionStatistic(c.person.first_name, c.person.last_name,'',
                c.party_description,c.vote,c.candidate_status_description);
          } else {
            election.opponents.push(
              new ElectionStatistic(c.person.first_name, c.person.last_name,'',
                c.party_description,c.vote,c.candidate_status_description))
          }
        });
        electionStats.generalElections.push(election);
      }
    });
    return electionStats;
  }

}

export default new ElectionService();
