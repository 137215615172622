export const CandidateSearchRoutes = {
  path: "candidate-search",
  meta: {
    breadcrumb: {
      name: 'Candidate Search',
    },
  },
  children:[
    {
      path:"",
      name: "Candidate Search",
      component: () => import("../../views/auth/resources/candidate_search/CandidateSearch"),
    },
    {
      path: "candidate-info/:candidateId",
      name: "Candidate Info",
      meta: {
        breadcrumb: {
          name: 'Candidate Info',
        },
      },
      component: () => import("../../views/auth/resources/candidate_search/CandidateInfo"),
      props: true,
    },
    {
      path: "candidate-locator",
      name: "Candidate Locator",
      meta: {
        breadcrumb: {
          name: 'Candidate Locator',
        },
      },
      component: () => import("../../views/auth/resources/candidate_search/CandidateLocator"),
    },
  ],
};
