import {ElectionStatistics} from "@/models/common/election-statistics";

export class CandidateAvatar {
  photoUrl= '';
  firstname='';
  lastname='';
  cycle= '';
  chamber='';
  districtNum= 0;
  constructor(photoUrl= '', firstname='', lastname='',
              cycle= '', chamber= '', districtNum= 0) {
    this.photoUrl = photoUrl;
    this.firstname = firstname;
    this.lastname = lastname;
    this.cycle = cycle;
    this.chamber = chamber;
    this.districtNum = districtNum;
  }
}

export class Candidate {
  id = '';
  avatar = new CandidateAvatar();
  race = '';
  party = '';
  status = '';
  contact={
    email:'',
    phone:'',
    address1:'',
    address2:'',
    address3:'',
    city:'',
    state:'',
    zip: ''
  };
  electionIds = [];
  electionStats= new ElectionStatistics();
}
