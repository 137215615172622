export class Bill{
    bid = '';
    name= '';
    author= '';
    description = '';
    lastAction= '';
    date='';
    track=false;
  constructor(_id='', name= '', author= '', description = '',
              lastAction= '', date= '', track= false){
    this.bid = _id;
    this.name= name;
    this.author= author;
    this.description = description;
    this.lastAction= lastAction;
    this.date=date;
    this.track=track;
  }
}

export class LegislationAuthored {
  listCoSponsorMemos= [];
  listLegislationAuthored= [];
  listLegislationEnacted= [];
  listLegislationCoSponsored= [];
  billResearchReport= [];
}

export const AmendmentsAuthored = [];
