import AuthService from '@/services/auth.service'
import { Module } from "vuex";
import {ActiveSessions} from "@/models/common/session";

class UserState {
  userId= null;
  types= null;
  activeSessions= new ActiveSessions();
  constructor(activeSessions:ActiveSessions, username?:any, types?:any) {
    this.userId = username;
    this.types = types;
    this.activeSessions = activeSessions;
  }
}
class TypeValue {
  id='';
  description='';
}
class Types{
  name = '';
  values: TypeValue[] = [];
}
const user = localStorage.getItem('user');
const types: Types[] = JSON.parse(localStorage.getItem('types') as any) as Types[];
const sessionState = JSON.parse(localStorage.getItem('session_state') as any) as ActiveSessions;

export const auth:Module<UserState, any> = {
    namespaced: true,
    state: user ? new UserState(sessionState, user, types) : new UserState(new ActiveSessions()),
    actions: {
        login({ commit, dispatch }, user) {
            return AuthService.login(user).then((uid: string) => {
                    commit('loginSuccess', uid);
                    dispatch('updateSessions');
                    return user;
                    }, (error:any) => {
                    commit('loginFailure');
                    return Promise.reject(error);
                })
        },
        updateSessions({commit, dispatch}){
          return AuthService.loadSessions().then((sessionState:ActiveSessions)=> {
            if(sessionState.sessions)
              commit('setSessions', sessionState.sessions);
            if(sessionState.selectedSession)
              commit('setCurSession', sessionState.selectedSession);
            dispatch('updateTypes');
            return sessionState;
          }, (error:any) => {
            return Promise.reject(error);
          });
        },
        updateTypes({commit}){
          return AuthService.loadTypes().then((types:Types[])=>{
            if(types)
              commit('setTypes', types);
            return types;
          }, (error:any) => {
            return Promise.reject(error);
          });
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },
        selectSession({ commit }, session) {
          commit('setCurSession', session)
        },
    },
    mutations: {
        loginSuccess(state, uid) {
            state.userId = uid
        },
        loginFailure(state) {
          localStorage.removeItem('user');
        },
        logout(state) {
            state.userId = null
        },
        setCurSession(state, curSession){
          state.activeSessions.selectedSession = curSession;
          localStorage.setItem('session_state', JSON.stringify(state.activeSessions));
        },
        setSessions(state, sessions){
          state.activeSessions.sessions = sessions;
          localStorage.setItem('session_state', JSON.stringify(state.activeSessions));
        },
        setTypes(state, types){
          state.types = types;
          localStorage.setItem('types', JSON.stringify(state.types));
        }
    },
    getters:{
      getCurrentSession(state){
        return state.activeSessions.selectedSession;
      },
      getSessions(state){
        return state.activeSessions.sessions;
      },
      getUserId(state){
        return state.userId;
      },
      getTypes(state){
        return state.types;
      }
    }
}
