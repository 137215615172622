import LegislatorInfoService from '../services/legislator-info.service'
import {Legislator} from "../models/legislator_info/legislator";
import ElectionService from "../services/common/election.service";

export const legislatorInfoModule = {
  namespaced: true,
  state: {
    legislatorInfo: new Legislator()
  },
  actions: {
    loadLegislator({ commit }, lid) {
      return LegislatorInfoService.getLegislatorById(lid).then(legislator=>commit('load', legislator));
    },
    loadElectionsData({ commit }, payload){
      return ElectionService.getElections(payload[0],payload[1]).then(
        elections => {
          commit('loadElectionData', elections);
          return Promise.resolve(elections);
        },
        error => {
          commit('loadFailure');
          return Promise.reject(error);
        }
      )
    },
    loadBillData({ commit }, payload){
      return LegislatorInfoService.getBillDataByLegislator(payload[0], payload[1]).then(
        bills => {
          commit('loadMemberPairingData', bills[0]);
          commit('loadLegislationAuthored', bills[1]);
          return Promise.resolve(bills);
        },
        error => {
          commit('loadFailure');
          return Promise.reject(error);
        }
      )
    },
    loadAmendmentsData({commit}, payload) {
      return LegislatorInfoService.getAmendmentsDataByLegislator(payload[0], payload[1]).then(
        amendments => {
          console.log('Amendments', amendments);
          commit('loadAmendments', amendments);
          return Promise.resolve(amendments);
        },
        error => {
          commit('loadFailure');
          return Promise.reject(error);
        }
      )
    },
    unload({ commit }) {
      commit('unloaded');
    }
  },
  mutations: {
    load(state, legislator) {
      state.legislatorInfo = legislator;
    },
    loadElectionData(state, electionStat) {
      state.legislatorInfo.electionStats = Object.assign({},
        state.legislatorInfo.electionStats, electionStat);
    },
    loadMemberPairingData(state, memberPairs) {
      state.legislatorInfo.memberPairings = Object.assign({},
        state.legislatorInfo.memberPairings, memberPairs);
    },
    loadLegislationAuthored(state, legislation) {
      state.legislatorInfo.legislationAuthored = legislation;
    },
    loadAmendments(state, amendments) {
      state.legislatorInfo.amendmentsAuthored = amendments;
    },
    unloaded(state) {
      state.legislatorInfo = null;
    },
    loadFailure(state) {
      state.legislatorInfo = {};
    }
  },
  getters:{
    getLegislatorInfo(state){
      return state.legislatorInfo;
    },
    getMemberPairingData(state){
      return state.legislatorInfo.memberPairings;
    },
    getElectionStats(state){
      return state.legislatorInfo.electionStats;
    }
  }
}
