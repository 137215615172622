export class Pairing{
    bid = '';
    pid = '';
    sponsor= '';
    party = '';
    pair= 0;
    passed= 0;
    successRate=0;
  constructor(_bid='', _pid='', sponsor= '', party='',
              pair= 0, passed= 0, successRate= 0){
    this.bid = _bid;
    this.pid = _pid;
    this.sponsor= sponsor;
    this.party = party;
    this.pair= pair;
    this.passed= passed;
    this.successRate=successRate;
  }
}

export class MemberPairings {
  pairings= null
}

